import { IApp } from '@/interfaces';
import { AppsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setMyApps(state: AppsState, payload: IApp[]) {
    state.apps = payload;
  },
};

const { commit } = getStoreAccessors<AppsState, State>('');

export const commitSetMyApps = commit(mutations.setMyApps);
