import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ScansState } from './state';

const defaultState: ScansState = {
  scans: [],
  filters: [],
  versions: [],
  stats: [],
};

export const scansModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
