import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  users: (state: AdminState) => state.users,
  user: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  apps: (state: AdminState) => state.apps,
  app: (state: AdminState) => (appId: number) => {
    const filteredApps = state.apps.filter((app) => app.id === appId);
    if (filteredApps.length > 0) {
      return { ...filteredApps[0] };
    }
  },
  lastId: (state: AdminState) => state.lastId,
  selectedUsers: (state: AdminState) => state.selectedUsers,
  selectedApps: (state: AdminState) => state.selectedApps,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readUsers = read(getters.users);
export const readOneUser = read(getters.user);
export const readApps = read(getters.apps);
export const readOneApp = read(getters.app);
export const readLastId = read(getters.lastId);
export const readSelectedUsers = read(getters.selectedUsers);
export const readSelectedApps = read(getters.selectedApps);

export const TIMEZONES = [
  '(GMT+1:00) European Central Time (ECT)',
  '(GMT+2:00) East European Time (EET)',
  '(GMT+2:00) Egypt Standard Time (ART)',
  '(GMT+3:00) Eastern African Time (EAT)',
  '(GMT+3:30) Middle East Time (MET)',
  '(GMT+4:00) Near East Time (NET)',
  '(GMT+5:00) Pakistan Lahore Time (PLT)',
  '(GMT+5:30) India Standard Time (IST)',
  '(GMT+6:00) Bangladesh Standard Time (BST)',
  '(GMT+7:00) Vietnam Standard Time (VST)',
  '(GMT+8:00) China Taiwan Time (CTT)',
  '(GMT+9:00) Japan Standard Time (JST)',
  '(GMT+9:30) Australian Central Time (ACT)',
  '(GMT+10:00) Australian Eastern Time (AET)',
  '(GMT+11:00) Solomon Standard Time (SST)',
  '(GMT+12:00) New Zealand Standard Time (NST)',
  '(GMT-11:00) Midway Islands Time (MIT)',
  '(GMT-10:00) Hawaii Standard Time (HST)',
  '(GMT-9:00) Alaska Standard Time (AST)',
  '(GMT-8:00) Pacific Standard Time (PST)',
  '(GMT-7:00) Phoenix Standard Time (PNT)',
  '(GMT-7:00) Mountain Standard Time (MST)',
  '(GMT-6:00) Central Standard Time (CST)',
  '(GMT-5:00) Eastern Standard Time (EST)',
  '(GMT-5:00) Indiana Eastern Standard Time (IET)',
  '(GMT-4:00) Puerto Rico and US Virgin Islands Time (PRT)',
  '(GMT-3:30) Canada Newfoundland Time (CNT)',
  '(GMT-3:00) Argentina Standard Time (AGT)',
  '(GMT-2:00) Brazil Eastern Standard Time (BET)',
  '(GMT-1:00) Central African Time (CAT)',
];
