import { api } from '@/api';
import { ActionContext } from 'vuex';
import {
  IApp,
  IAppCreate,
  IUserProfile,
  IUserProfileCreate,
  IUserProfileUpdate,
} from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetUsers,
  commitSetUser,
  commitSetApps,
  commitSetApp,
  commitSetSelectedUsers,
  commitSetLastId,
  commitSetSelectedApps,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitAddNotification,
  commitRemoveNotification,
} from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    const notification = { content: 'Saving new user', showProgress: true };
    try {
      commitAddNotification(context, notification);
      const response = await api.createUser(
        context.rootState.main.token,
        payload,
      );
      commitSetUser(context, response.data);
      commitSetLastId(context, response.data.id);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'User successfully created',
        color: 'success',
      });
    } catch (error) {
      commitSetLastId(context, 0);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error creating user',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserProfileUpdate },
  ) {
    const notification = { content: 'Saving user', showProgress: true };
    try {
      commitAddNotification(context, notification);
      const response = await api.updateUser(
        context.rootState.main.token,
        payload.id,
        payload.user,
      );
      commitSetUser(context, response.data);
      commitSetLastId(context, response.data.id);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'User successfully updated',
        color: 'success',
      });
    } catch (error) {
      commitSetLastId(context, 0);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error updating user',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetApps(context: MainContext) {
    try {
      const response = await api.getApps(context.rootState.main.token);
      if (response) {
        commitSetApps(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateApp(context: MainContext, payload: IAppCreate) {
    const notification = {
      content: 'Saving new application',
      showProgress: true,
    };
    try {
      commitAddNotification(context, notification);
      const response = await api.createApp(
        context.rootState.main.token,
        payload,
      );
      commitSetApp(context, response.data);
      commitSetLastId(context, response.data.id);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Application successfully created',
        color: 'success',
      });
    } catch (error) {
      commitSetLastId(context, 0);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error creating application',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateApp(
    context: MainContext,
    payload: { id: number; app: IAppCreate },
  ) {
    const notification = { content: 'Saving application', showProgress: true };
    try {
      commitAddNotification(context, notification);
      const response = await api.updateApp(
        context.rootState.main.token,
        payload.id,
        payload.app,
      );
      commitSetApp(context, response.data);
      commitSetLastId(context, response.data.id);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Application successfully updated',
        color: 'success',
      });
    } catch (error) {
      commitSetLastId(context, 0);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error updating application',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetSelectedUsers(
    context: MainContext,
    payload: { appId: number },
  ) {
    try {
      const response = await api.getUsersByApp(
        context.rootState.main.token,
        payload.appId,
      );
      if (response) {
        commitSetSelectedUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetSelectedApps(
    context: MainContext,
    payload: { userId: number },
  ) {
    try {
      const response = await api.getAppsByUser(
        context.rootState.main.token,
        payload.userId,
      );
      if (response) {
        commitSetSelectedApps(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionAssignUsers(
    context: MainContext,
    payload: { appId: number; users: IUserProfile[] },
  ) {
    try {
      await api.assignUsers(
        context.rootState.main.token,
        payload.appId,
        payload.users,
      );
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionAssignApps(
    context: MainContext,
    payload: { userId: number; apps: IApp[] },
  ) {
    try {
      await api.assignApps(
        context.rootState.main.token,
        payload.userId,
        payload.apps,
      );
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchGetApps = dispatch(actions.actionGetApps);
export const dispatchCreateApp = dispatch(actions.actionCreateApp);
export const dispatchUpdateApp = dispatch(actions.actionUpdateApp);
export const dispatchGetSelectedUsers = dispatch(
  actions.actionGetSelectedUsers,
);
export const dispatchGetSelectedApps = dispatch(actions.actionGetSelectedApps);
export const dispatchAssignUsers = dispatch(actions.actionAssignUsers);
export const dispatchAssignApps = dispatch(actions.actionAssignApps);
