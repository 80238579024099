import Vue from 'vue';
import ECharts from 'vue-echarts';
import { use } from 'echarts/core';

import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';

use([
  TitleComponent,
  LegendComponent,
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
]);

Vue.component('v-chart', ECharts);
