const env = process.env.VUE_APP_ENV;

let envApiUrl = '';

if (env === 'production') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === 'staging') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
}

const apiVer = '/api/v1';

export const apiUrl = envApiUrl + apiVer;
export const appName = process.env.VUE_APP_NAME;
export const appVersion = process.env.VUE_APP_VERSION;
export const googleKey = process.env.VUE_APP_GOOGLE_KEY;
