import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IConsumer,
  IScan,
  IApp,
  IProduct,
  ITarget,
  IVersion,
  IAppCreate,
  IConsumerCreate,
  IFilterItem,
  IScanStats,
  IConsumerStats,
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

function serializeFilters(filters: IFilterItem[]) {
  return JSON.stringify(
    filters.map((el) => {
      return {
        type: el.type,
        value: el.value,
      };
    }),
  );
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/users/me`,
      data,
      authHeaders(token),
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/users`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/users`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getConsumers(
    token: string,
    appId: number,
    skip: number,
    limit: number,
    sort: string,
    desc: boolean,
  ) {
    return axios.get<IConsumer[]>(
      `${apiUrl}/consumers/${appId}?skip=${skip}&limit=${limit}&sort=${sort}&desc=${desc}`,
      authHeaders(token),
    );
  },
  async exportConsumers(token: string, appId: number) {
    return axios.post(
      `${apiUrl}/consumers/${appId}/export`,
      [],
      authHeaders(token),
    );
  },
  async getConsumerStats(token: string, appId: number) {
    return axios.post<IConsumerStats[]>(
      `${apiUrl}/consumers/${appId}/stats`,
      [],
      authHeaders(token),
    );
  },
  async exportConsumerStats(token: string, appId: number) {
    return axios.post(
      `${apiUrl}/consumers/${appId}/stats/export`,
      [],
      authHeaders(token),
    );
  },
  async checkUniqueConsumer(
    token: string,
    appId: number,
    consumerId: number,
    name: string,
  ) {
    return axios.get<boolean>(
      `${apiUrl}/consumers/${appId}/${consumerId}/unique?name=${name}`,
      authHeaders(token),
    );
  },
  async createConsumer(token: string, appId: number, data: IConsumerCreate) {
    return axios.post(`${apiUrl}/consumers/${appId}`, data, authHeaders(token));
  },
  async updateConsumer(
    token: string,
    appId: number,
    consumerId: number,
    data: IConsumerCreate,
  ) {
    return axios.put(
      `${apiUrl}/consumers/${appId}/${consumerId}`,
      data,
      authHeaders(token),
    );
  },
  async getMyApps(token: string) {
    return axios.get<IApp[]>(`${apiUrl}/apps/my`, authHeaders(token));
  },
  async getApps(token: string) {
    return axios.get<IApp[]>(`${apiUrl}/apps`, authHeaders(token));
  },
  async createApp(token: string, data: IAppCreate) {
    return axios.post(`${apiUrl}/apps`, data, authHeaders(token));
  },
  async updateApp(token: string, appId: number, data: IAppCreate) {
    return axios.put(`${apiUrl}/apps/${appId}`, data, authHeaders(token));
  },
  async getUsersByApp(token: string, appId: number) {
    return axios.get<IUserProfile[]>(
      `${apiUrl}/ul/app/${appId}`,
      authHeaders(token),
    );
  },
  async getAppsByUser(token: string, userId: number) {
    return axios.get<IApp[]>(`${apiUrl}/ul/user/${userId}`, authHeaders(token));
  },
  async assignUsers(token: string, appId: number, data: IUserProfile[]) {
    return axios.put(`${apiUrl}/ul/app/${appId}`, data, authHeaders(token));
  },
  async assignApps(token: string, userId: number, data: IApp[]) {
    return axios.put(`${apiUrl}/ul/user/${userId}`, data, authHeaders(token));
  },
  async getScans(
    token: string,
    appId: number,
    filters: IFilterItem[],
    skip: number,
    limit: number,
    sort: string,
    desc: boolean,
  ) {
    return axios.get<IScan[]>(
      `${apiUrl}/scans/${appId}?filters=${serializeFilters(filters)}
      &skip=${skip}&limit=${limit}&sort=${sort}&desc=${desc}`,
      authHeaders(token),
    );
  },
  async exportScans(token: string, appId: number, filters: IFilterItem[]) {
    return axios.post(
      `${apiUrl}/scans/${appId}/export`,
      filters,
      authHeaders(token),
    );
  },
  async getScanStats(token: string, appId: number, filters: IFilterItem[]) {
    return axios.post<IScanStats[]>(
      `${apiUrl}/scans/${appId}/stats`,
      filters,
      authHeaders(token),
    );
  },
  async exportScanStats(token: string, appId: number, filters: IFilterItem[]) {
    return axios.post(
      `${apiUrl}/scans/${appId}/stats/export`,
      filters,
      authHeaders(token),
    );
  },
  async getTargets(token: string, appId: number) {
    return axios.get<ITarget[]>(
      `${apiUrl}/targets/${appId}`,
      authHeaders(token),
    );
  },
  async getProducts(token: string, appId: number) {
    return axios.get<IProduct[]>(
      `${apiUrl}/products/${appId}`,
      authHeaders(token),
    );
  },
  async getVersions(token: string, appId: number) {
    return axios.get<IVersion[]>(
      `${apiUrl}/scans/${appId}/versions`,
      authHeaders(token),
    );
  },
};
