import { IFilterItem, IScan, IScanStats, IVersion } from '@/interfaces';
import { ScansState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  clearScans(state: ScansState) {
    state.scans = [];
  },
  setScans(state: ScansState, payload: { data: IScan[]; append: boolean }) {
    state.scans = payload.append
      ? state.scans.concat(payload.data)
      : payload.data;
  },
  clearFilters(state: ScansState) {
    state.filters = [];
  },
  setFilters(state: ScansState, payload: { data: IFilterItem[] }) {
    state.filters = payload.data;
  },
  addFilter(state: ScansState, payload: { data: IFilterItem }) {
    state.filters = state.filters.concat(payload.data);
  },
  removeFilter(state: ScansState, payload: { data: IFilterItem }) {
    state.filters.splice(state.filters.indexOf(payload.data), 1);
  },
  setVersions(state: ScansState, payload: { data: IVersion[] }) {
    state.versions = payload.data;
  },
  setScanStats(state: ScansState, payload: { data: IScanStats[] }) {
    state.stats = payload.data;
  },
};

const { commit } = getStoreAccessors<ScansState, State>('');

export const commitClearScans = commit(mutations.clearScans);
export const commitSetScans = commit(mutations.setScans);
export const commitClearFilters = commit(mutations.clearFilters);
export const commitSetFilters = commit(mutations.setFilters);
export const commitAddFilter = commit(mutations.addFilter);
export const commitRemoveFilter = commit(mutations.removeFilter);
export const commitSetVersions = commit(mutations.setVersions);
export const commitSetScanStats = commit(mutations.setScanStats);
